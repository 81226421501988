var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"page-layout"},[_c('gc-header',{attrs:{"headerData":"alarmDevice"}}),_c('div',{staticClass:"run-main"},[_c('div',{staticClass:"serach"},[_c('gc-custom-search',{attrs:{"keyWord":"设备编号","width":"300px","search":_vm.form.deviceNo},on:{"update:search":function($event){return _vm.$set(_vm.form, "deviceNo", $event)}}}),(_vm.tenantType == 1)?_c('gc-custom-search',{attrs:{"keyWord":"客户类型","width":"300px","type":"select","search":_vm.form.userType,"searchOption":_vm.options.userType,"needAllForSearch":true,"searchOptionKey":{
          label: 'name',
          value: 'defaultValue',
        }},on:{"update:search":function($event){return _vm.$set(_vm.form, "userType", $event)}}}):_vm._e(),(_vm.tenantType != 1)?_c('gc-custom-search',{attrs:{"keyWord":"设备类别","width":"300px","type":"select","filterable":"","search":_vm.form.firstCategory,"searchOption":_vm.options.firstCategory,"searchOptionKey":{
          label: 'name',
          value: 'productCategoryId',
        },"needAllForSearch":true},on:{"update:search":function($event){return _vm.$set(_vm.form, "firstCategory", $event)}}}):_vm._e(),(_vm.tenantType != 1)?_c('gc-custom-search',{attrs:{"keyWord":"设备类型","width":"300px","type":"select","filterable":"","search":_vm.form.deviceTypeId,"searchOption":_vm.options.deviceTypeId,"needAllForSearch":true},on:{"update:search":function($event){return _vm.$set(_vm.form, "deviceTypeId", $event)}}}):_vm._e(),_c('div',{staticClass:"serach-ops"},[_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1, _vm.apiGetAlarmDeviceList)}}},[_vm._v("查 询")]),_c('el-button',{directives:[{name:"click-blur",rawName:"v-click-blur"}],on:{"click":function($event){return _vm.reset(_vm.apiGetAlarmDeviceList)}}},[_vm._v("重 置")])],1)],1),_c('div',{staticClass:"table-show"},[_c('gc-table',{attrs:{"columns":_vm.columns,"table-data":_vm.tableData,"border":true,"showPage":true,"total":_vm.page.total,"page-size":_vm.page.size,"currentPage":_vm.page.current},on:{"current-page-change":function (currentPage) {
            _vm.currentPageChange(currentPage, _vm.apiGetAlarmDeviceList);
          }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }